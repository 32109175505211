export const homeObjOne = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'BLACK BOX PENTESTING',
  headline: 'Seguridad Web',
  description:'Probamos y analizamos la seguridad de su infraestructura expuesta al internet (servidores, aplicaciones, recursos y servicios) y sugerimos un curso a seguir basado en la evidencia recolectada durante el análisis.',
  buttonLabel: 'Shop Now',
  imgStart: '',
  img: 'images/Seguridad1.png',
  alt: 'Black Box Pentesting'
};

export const homeObjTwo = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'GRAY BOX PENTESTING',
  headline: 'Penetración en Sitio',
  description:'Probamos y analizamos la seguridad de su infraestructura local que incluye redes informáticas alámbricas e inalámbricas, vulnerabilidad de activos críticos tanto equipo informático como individuos indispensables para su operación diaria.',
  buttonLabel: 'Learn More',
  imgStart: 'start',
  img: 'images/Seguridad2.png',
  alt: 'Vault'
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'WHITE BOX PENTESTING',
  headline: 'Aplicaciones Internas',
  description:"En el análisis de aplicaciones internas pedimos el acceso a las aplicaciones y ordenadores utilizados en todos los aspectos de la operación para hacer una evaluación de todos los riesgos, tanto críticos como potenciales que puedan existir y llevar a cabo un reporte detallado de todos los hallazgos. Para considerar un plan de acción pertinente al escenario descubierto.",
  buttonLabel: 'Start Now',
  imgStart: '',
  img: 'images/Seguridad3.png',
  alt: 'Vault'
};

export const homeObjFour = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'CIS CONTROLS - SGSI',
  headline: 'Políticas de Seguridad',
  description:'Desarrollamos el Sistema de Gestión de la Seguridad de la Información (SGSI) y los controles subsecuentes después de analizar la operativa de las aplicaciones, la distribución de recursos y los requerimientos administrativos y comerciales considerando las normas y leyes que apliquen según las actividades para cuáles los activos están destinados. Cumpliendo con los estándares y certificaciones internacionales de seguridad.',
  buttonLabel: 'Sign Up Now',
  imgStart: 'start',
  img: 'images/Seguridad4.png',
  alt: 'SGSI'
};

export const homeObjFive = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'PHISHING E INGENIERIA SOCIAL',
  headline: 'Capacitación de Personal',
  description:'Creamos campañas de concientización de personal entrenándolos en los escenarios más probables de ataques que puedan suceder en la vida real con el proposito de que logren comprender lo vulnerables que los individuos pueden ser cuando un atacante se pone como objetivo comprometer una organización o los individuos por sí mismos.',
  buttonLabel: 'Sign Up Now',
  imgStart: '',
  img: 'images/Seguridad5.png',
  alt: '¨Phishing'
};

export const homeObjSix = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'EDR / ANTIVIRUS / VS',
  headline: 'Programa de Monitoreo',
  description:'Establecemos permanencia en las redes de nuestros clientes para vigilar constantemente los activos y el tráfico de la organización e identificar de una manera eficaz vulnerabilidades o amenazas que en un momento dado se puedan presentar dentro de la operación. Para erradicarlas y prevenir la perdida de confidencialidad, integridad o disponibilidad de los servicios.',
  buttonLabel: 'Sign Up Now',
  imgStart: 'start',
  img: 'images/Seguridad6.png',
  alt: 'Phishing'
};