import React ,{ useState, useEffect, setState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Button } from './Button';
import "./Navbar.css";
import { IconContext } from 'react-icons/lib'; 
import { FcWorkflow } from "react-icons/fc";

function Navbar() {
    
    const [click, setClick] = useState(false)
    
    const [button, setButton] = useState(true)

    const [name, setName] = React.useState({ 
        text: "CSL"
      })

    const handleClick = () => setClick(!click)
    
    const closeMobileMenu = () => setClick(false)
    
    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        }else{
             setButton(true);
        }
    }

    function defineName(){
        if (window.innerWidth <= 960) {
            setName({text:"CSL"})
        }else{
            setName({text:"Computación Sin Limites"})
        }
    }
    

    React.useEffect(() => {
        
        defineName();   

        window.addEventListener('resize',defineName);
        return _ => {
            window.removeEventListener('resize', defineName)
        }
        
    },[])

    return (
        <>
        <IconContext.Provider value={{color: '#fff' }}>
        <div className="navbar">
            <div className="navbar-container container">
                <Link to="/" className="navbar-logo" onClick={closeMobileMenu}> 
                    <FcWorkflow className="navbar-icon" />
                    {name.text}
                </Link>
                <div className="menu-icon" onClick={handleClick}>
                    {click ? <FaTimes /> : <FaBars />}
                </div>
                <ul className={ click ? 'nav-menu active' : 'nav-menu '}>
                    <li className="nav-item">
                        <Link to="/desarrollo" className="nav-links" onClick={closeMobileMenu}>
                            Desarrollo
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/seguridad" className="nav-links" onClick={closeMobileMenu}>
                            Seguridad
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/infraestructura" className="nav-links" onClick={closeMobileMenu}>
                            Infraestructura
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/inteligencia-artificial" className="nav-links" onClick={closeMobileMenu}>
                            Ciencia de Datos
                        </Link>
                    </li>
                    <li className="nav-btn">
                        { button ? (
                            <Link to="/sign-up" className="btn-link" onClick={closeMobileMenu}>
                                <Button buttonStyle="btn--outline"  buttonSize="btn--mobile" style={{width:'70%'}}>
                                    Contacto
                                </Button>
                            </Link>
                        ):(
                            <Link to="/sign-up" className="btn-link">
                                <Button buttonStyle="btn--outline">
                                    Registrate
                                </Button>
                            </Link>
                        )
                        }
                    </li>
                </ul>
            </div>
        </div>
        </IconContext.Provider>
        </>
    )
}

export default Navbar
