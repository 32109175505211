import React from 'react';
import './Footer.css';
import { Button } from '../../Button';
import { Link } from 'react-router-dom';
import {
  FaFacebook,
  FaWhatsapp,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
  FaEnvelope,
  FaPhoneSquareAlt,
  FaExclamationTriangle
} from 'react-icons/fa';
import { FcWorkflow } from "react-icons/fc";

function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Contactanos para mas información
        </p>
        <p className='footer-subscription-text'>
          <FaEnvelope />julio@computacionsinlimites.com.mx
        </p>
        <p className='footer-subscription-text'>
          <FaPhoneSquareAlt />+52 8116787614
        </p>
        <p className='footer-subscription-text'>
          <FaExclamationTriangle />Sitio en construcción
        </p>
        </section>
        {/* <div className='input-areas'>
          <form>
            <input
              className='foote<r-input'
              name='email'
              type='email'
              placeholder='Your Email'
            />
            <Button buttonStyle='btn--outline'>Subscribe</Button>
          </form>
        </div>
      </section>
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>About Us</h2>
            <Link to='/sign-up'>How it works</Link>
            <Link to='/'>Testimonials</Link>
            <Link to='/'>Careers</Link>
            <Link to='/'>Investors</Link>
            <Link to='/'>Terms of Service</Link>
          </div>
          <div className='footer-link-items'>
            <h2>Contact Us</h2>
            <Link to='/'>Contact</Link>
            <Link to='/'>Support</Link>
            <Link to='/'>Destinations</Link>
            <Link to='/'>Sponsorships</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Videos</h2>
            <Link to='/'>Submit Video</Link>
            <Link to='/'>Ambassadors</Link>
            <Link to='/'>Agency</Link>
            <Link to='/'>Influencer</Link>
          </div>
          <div className='footer-link-items'>
            <h2>Social Media</h2>
            <Link to='/'>Whatsapp</Link>
            <Link to='/'>Facebook</Link>
            <Link to='/'>Youtube</Link>
            <Link to='/'>Twitter</Link>
          </div>
        </div> 
  </div> */}
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
              <FcWorkflow className='navbar-icon' />
            </Link>
          </div>
          <small className='website-rights'>COMPUTACION SIN LIMITES © 2021</small>
          <div className='social-icons'>
            <Link
              className='social-icon-link'
              to= {{ pathname: 'https://www.facebook.com/computacionsinlimites' }}
              target='_blank'
              aria-label='Facebook'
            >
              <FaFacebook />
            </Link>
            <Link
              className='social-icon-link'
              to= {{ pathname: 'https://api.whatsapp.com/send?phone=528116787614&text=Hola%20me%20interesan%20los%20servicios%20de%20computacion%20sin%20limites' }}
              target='_blank'
              aria-label='Whatsapp'
            >
              <FaWhatsapp />
            </Link>
            <Link
              className='social-icon-link'
              to={{ pathname: 'https://www.linkedin.com/in/julio-ontiveros-3135b258/' }}
              target='_blank'
              aria-label='LinkedIn'
            >
              <FaLinkedin />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
