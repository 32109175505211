export const homeObjOne = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'DESARROLLO',
  headline: 'Desarrollo de Software',
  description:
    'Proveemos el desarrollo de software o integración de software buscando siempre la satisfacción de nuestros clientes y la mejora de sus procesos teniendo como objetivo primordial reducir costos, aumentar la utilidad y otorgarle una mayor capacidad de atención al cliente.',
  link: true,
  linkValue: "/desarrollo",
  buttonLabel: 'Mas detalles',
  imgStart: '',
  img: 'images/temp/desarrollo-software.jpg',
  alt: 'Desarrollo de Software'
};

export const homeObjTwo = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'SEGURIDAD',
  headline: 'Seguridad Informática',
  description:
    'Utilizamos un amplio rango de técnicas para probar la seguridad de las aplicaciones de nuestros clientes, sitios web, redes locales y activos como equipos de cómputo o individuos. También creamos y gestionamos políticas de seguridad informática para cumplir con los estándares internacionales y normas oficiales que puedan aplicar según las necesidades de nuestros clientes.',
  buttonLabel: 'Mas detalles',
  imgStart: 'start',
  link:true,
  linkValue: "/seguridad",
  img: 'images/temp/seguridad-informatica.jpg',
  alt: 'Seguridad Informatica'
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'INFRAESTRUCTURA',
  headline: 'Infraestructura de Cómputo',
  description:
    "Asesoramos o implementamos la instalación y gestión de infraestructura de cómputo como lo son servidores, cableado, puntos de acceso inalámbricos y computadoras personales. Para satisfacer las necesidades de nuestros clientes en instalaciones locales o servicios en la nube.",
  buttonLabel: 'Mas detalles',
  imgStart: '',
  link: true,
  linkValue: "/infraestructura",
  img: 'images/temp/infraestructura-computo.jpg',
  alt: 'Infraestructura de Computo'
};

export const homeObjFour = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'CIENCIA DE DATOS',
  headline: 'Ciencia de Datos e Inteligencia Artificial',
  description:
    'Usamos la ciencia de datos y el aprendizaje de máquina para definir y solucionar concretamente la problemática que puedan enfrentar nuestros clientes que no se puedan solucionar con programación tradicional o incidentes inesperados que estén fuera de control por causas desconocidas.',
  link: true,
  linkValue: "/inteligencia-artificial",
  buttonLabel: 'Mas detalles',
  imgStart: 'start',
  img: 'images/temp/inteligencia-artificial.jpg',
  alt: 'Inteligencia Artificial'
};
