import React ,{useState} from 'react'

function IsMobile(){
    const [mobile, setMobile] = useState(false);

 function defineMobile(){
    if (window.innerWidth <= 960) {
        setMobile(true);
    }else{
        setMobile(false);
    }
  }

  React.useEffect(() => {
        
    defineMobile();   

    window.addEventListener('resize',defineMobile);
    return _ => {
        window.removeEventListener('resize', defineMobile)
    }
    
   },[])
   return mobile
}
export default IsMobile