export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Desarrollo personalizado',
  headline: 'A la medida',
  description:'Después de analizar las necesidades de nuestros clientes podemos proponer el curso a seguir necesario para satisfacer estas necesidades considerando como prioridad sus intereses, tomando en cuenta los recursos necesarios e indispensables para cumplir con el objetivo.',
  buttonLabel: 'Get Started',
  imgStart: '',
  img: 'images/Diseno1.png',
  alt: 'Diseno personalizado'
};

export const homeObjTwo = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Compatibilidad',
  headline: 'Integraciones',
  description:'Tenemos experiencia integrando diferentes sistemas para eficientizar el flujo de trabajo de nuestros clientes aumentando la productividad, reduciendo costos y mejorando la atención al cliente (usuario final).',
  buttonLabel: 'Learn More',
  imgStart: 'start',
  img: 'images/Diseno2.png',
  alt: 'Integraciones'
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Discernimiento',
  headline: 'Análisis Exhaustivo',
  description:
    "Trabajamos con nuestros clientes para considerar todos los aspectos de su negocio de una manera integral y establecer sus necesidades inmediatas para desarrollar una lista de objetivos que cumplan con las expectativas comerciales y administrativas considerando todas las posibles soluciones ya sea implementando productos existentes, creando soluciones a la medida o una combinación de ambos.",
  buttonLabel: 'Start Now',
  imgStart: 'start',
  img: 'images/Diseno3.png',
  alt: 'Analisis Exhaustivo'
};

export const homeObjFour = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Soporte y Seguimiento',
  headline: 'Implementación',
  description:
    'Acompañamos a nuestros clientes durante la implementación, entrenamiento y periodos de prueba de las soluciones implementadas hasta el punto donde queden completamente satisfechos con el servicio proporcionado  ya sea de productos de terceros o soluciones creadas a sus necesidades.',
  buttonLabel: 'Sign Up Now',
  imgStart: '',
  img: 'images/Diseno4.png',
  alt: 'Implementacion'
};
