export const homeObjOne = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Data Science',
  headline: 'Investigación Cientifica',
  description:
    'Le ayudamos a nuestros clientes a desmenuzar su problema en hipótesis y llevar a cabo las investigaciones pertinentes utilizando el método científico para corroborar o desacreditar hipótesis y llegar a una conclusión definitiva de las causalidades del problema.',
  buttonLabel: 'Get Started',
  imgStart: '',
  img: 'images/temp/investigacion-cientifica.jpg',
  alt: 'Credit Card'
};

export const homeObjTwo = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Machine Learning / Deep Learning',
  headline: 'Predicción de Datos',
  description:
    'Utilizamos el aprendizaje de máquina entrenando redes neuronales para encontrar patrones en los datos y poder predecir alguna variable en particular en caso que esta sea la solución para el problema que tiene nuestro cliente.',
  buttonLabel: 'Learn More',
  imgStart: 'start',
  img: 'images/temp/prediccion-datos.jpg',
  alt: 'Vault'
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Machine Learning / Deep Learning',
  headline: 'Clasificación',
  description:
    "Entrenamos redes neuronales para clasificar imágenes o datos, esto en caso que sea la solución para el problema que tiene nuestro cliente",
  buttonLabel: 'Start Now',
  imgStart: '',
  img: 'images/temp/clasificacion.jpg',
  alt: 'Vault'
};

export const homeObjFour = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Machine Learning / Deep Learning',
  headline: 'Chat Bots',
  description:
    'Podemos entrenar redes neuronales a manejar conversaciones simples con los usuarios finales de nuestros clientes para atender casos sencillos como saldos pendientes, estado de una orden o asignar un agente de atención',
  buttonLabel: 'Sign Up Now',
  imgStart: 'start',
  img: 'images/temp/chat-bots.jpg',
  alt: 'Vault'
};
