import React from 'react';
import './App.css';
import Home from './components/pages/Home/Home';
import Desarrollo from "./components/pages/Desarrollo/Desarrollo";
import Seguridad from "./components/pages/Seguridad/Seguridad";
import Infraestructura from "./components/pages/Infraestructura/Infraestructura";
import Imagen from "./components/pages/Imagen/Imagen";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/pages/Footer.js/Footer';
import CienciaDatos from './components/pages/CienciaDatos/CienciaDatos';

function App() {
  
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/desarrollo' component={Desarrollo} />
        <Route path='/seguridad' component={Seguridad} />
        <Route path='/infraestructura' component={Infraestructura} />
        <Route path='/inteligencia-artificial' component={CienciaDatos} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
