export const homeObjOne = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Servidores Windows',
  headline: 'Control Organizacional',
  description:
    'Asistimos con la instalación y la gestión de servidores e infraestructura correspondiente para mantener un control organizacional y cumplir con los estándares y normas de manejo de la información y acceso de datos. Ya sea local o en un servicio de nube.',
  buttonLabel: 'Get Started',
  imgStart: '',
  img: 'images/temp/control-organizacional.jpg',
  alt: 'Credit Card'
};

export const homeObjTwo = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Servidores Linux',
  headline: 'Servicios en Internet',
  description:
    'Asistimos con la instalación y la gestión de servidores e infraestructura correspondiente para que nuestros clientes ofrezcan sus servicios informáticos en internet y en su red empresarial ya sea en una instalación local o en un servicio de nube.',
  buttonLabel: 'Learn More',
  imgStart: 'start',
  img: 'images/temp/servicios-internet.jpg',
  alt: 'Vault'
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Linux / Windows',
  headline: 'Monitoreo Empresarial',
  description:
    "Ayudamos a nuestros clientes lograr tener un acceso a un control y monitoreo del uso de los activos informáticos en su organización con servicio de bloqueo de sitios, alertas y distribución de ancho de banda.",
  buttonLabel: 'Start Now',
  imgStart: '',
  img: 'images/temp/monitoreo-empresarial.jpg',
  alt: 'Vault'
};

export const homeObjFour = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'IEEE 802',
  headline: 'Cableado Estructurado',
  description:
    'Asesoramos e implementamos instalaciones de cableado estructurado para cumplir con la normativa y las necesidades de nuestros clientes, incluyendo cuarto de servidores, armarios de telecomunicaciones, puntos de acceso inalámbricos, fibra óptica, WAN, MAN, LAN.',
  buttonLabel: 'Sign Up Now',
  imgStart: 'start',
  img: 'images/temp/cableado-estructurado.png',
  alt: 'Vault'
};

export const homeObjFive = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Data Center',
  headline: 'Telecomunicaciones',
  description:
    'Asesoramos e implementamos instalaciones para hospedar servidores, enrutadores, puntos de acceso inalámbricos, centros de datos, respaldos de datos. Con sistemas de respaldo de energía automático y con instalaciones anti vandálicas de alta seguridad.',
  buttonLabel: 'Sign Up Now',
  imgStart: '',
  img: 'images/temp/telecomunicaciones.jpg',
  alt: 'Vault'
};